@use 'sass:math';
@import '../../../styles/defaults.scss';

#unattended-ok {
    display: flex;
    justify-content: space-between;
    button {
        @extend .form-base-style;
        background-color: $primary-green-color;
        border-radius: $default-border-radius;
        color: $primary-white-color;
        padding: math.div($default-padding, 8);
        &:first-child {
            margin-right: $default-margin;
        }
        &:disabled {
            opacity: 0.5;
        }
        font-size: 100%;
        line-height: 1.5;
    }
}