@import '../../../styles/defaults.scss';

.default-icon-style {
    height: auto;
    width: 25px;
    margin: 0;
    padding: 0;
}

.package-icon-delivered {
    fill: $primary-green-color;
}

.package-icon-not-delivered {
    fill: $primary-red-color;
}
