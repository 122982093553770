@import '../../styles/defaults.scss';

$height: 100px;
$distans-from-bottom: 10vh;

.footer {
    align-items: center;
    bottom: $distans-from-bottom;
    display: flex;
    height: $height;
    justify-content: space-between;
    position: absolute;
    width: 100%;
}
