@import '../../styles/defaults.scss';

$e-drop-color: #52bbd2;

.eDropLogo {
    align-items: center;
    background-color: inherit;
    display: flex;
    height: 55px;
    justify-content: center;
    width: 100%;
    margin-bottom: $default-margin;

    img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        margin: 0 auto;
    }
}

.eDrop-btn {
    button {
        @extend .form-base-style;
        border-radius: $default-border-radius;
        background-color: $e-drop-color; // e-drop background color
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        &:active {
            filter: none;
            transition: transform 0.1s;
            transform: translateY(1px);
        }
    }
}

.e-drop-link {
    color: $e-drop-color;
}

.e-drop-message {
    color: $e-drop-color;
}
