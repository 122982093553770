@import '../../../styles/defaults.scss';

$default-animation-speed: 0.5s;

.wrapper {
    bottom: 0;
    color: $primary-white-color;
    background-color: $primary-black-color;
    height: 100%;
    overflow-y: scroll;
    max-width: $max-page-width;
    display: flex;
    flex-direction: column;
    position: absolute;
    animation: cardOpen $default-animation-speed forwards;
    right: 0;
    width: 100%;
    z-index: 20;

    @media screen and (max-width: $max-page-width) {
        left: 0;
        max-width: none;
    }

    .info-card-header {
        display: flex;
        justify-content: space-between;
        margin-top: $default-margin;
        padding: 0 $default-padding;
        background-color: inherit;
    }

    .info-card {
        background-color: inherit;
        padding: 0 $default-padding;
        margin-bottom: $default-margin * 4; // create a overflow effect
    }
}

/**
 * Full-size only shows up if the delivery sub route is not started
 * Keep class definition under .wrapper to unset "cardOpen" animation
 */
.full-size {
    animation: unset;
    left: 0;
    margin: 0 auto;
}

.open-info-card-btn {
    position: relative;
    align-items: center;
    background-color: $primary-black-color;
    border: none;
    border-radius: $left-border-radius;
    display: flex;
    filter: $default-box-shadow-left;
    height: 100%;
    justify-content: center;
    padding: $default-padding;
    animation: btnOpen $default-animation-speed forwards;
}

.sub-title {
    color: $primary-white-color;
    font-size: 22pt;
    font-weight: bold;
}

@keyframes btnOpen {
    from {
        right: -30%;
    }

    to {
        right: 0;
    }
}

@keyframes cardOpen {
    from {
        bottom: -75vh;
    }

    to {
        bottom: 0;
    }
}
