.header {
    align-items: flex-start;
    display: flex;
    width: 100%;
    z-index: 10;
    &.fixed {
        position: absolute;
        top: 0;
    }
}
