@import '../../styles/defaults.scss';
.button-wrapper {
    button {
        @extend .form-base-style;
        background-color: $primary-white-color;
        border-radius: $default-border-radius;
        color: $primary-black-color;
    }

    button:has(img) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;

        img {
            height: 50px;
            width: auto;
        }
    }
}

.save-btn {
    button {
        background-color: $primary-green-color;
        color: $primary-white-color;
    }
}