@use 'sass:math';
@import '../../styles/defaults.scss';

.sender-logo {
    align-items: center;
    background-color: inherit;
    display: flex;
    height: 55px;
    justify-content: center;
    max-width: 160px;
    padding: math.div($default-padding, 2);
    width: 100%;
    @media screen and (max-width: $break-iPhone8Plus) {
        height: 80px;
        max-width: 140px;
    }
    @media screen and (max-width: $break-iPhone5) {
        height: 70px;
        max-width: 95px;
    }
    img {
        height: auto;
        max-height: 100%;
        max-width: 100%;
        width: 100%;
    }
}
