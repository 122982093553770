@use 'sass:math';
/**
* Colors
*/
$primary-black-color: #4a4a49;
$primary-white-color: #ffffff;
$primary-grey-color: #999999;

$primary-blue-color: #06d4fe;
$primary-green-color: #39957b;
$primary-yellow-color: #ffd202;
$primary-red-color: #ff5e57;
$primary-pink-color: #f35899;

$default-border-radius: 10px;
$right-border-radius: 0px $default-border-radius $default-border-radius 0;
$left-border-radius: $default-border-radius 0 0 $default-border-radius;

$default-margin: 20px;
$default-padding: 20px;
$default-box-shadow-right: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.05));
$default-box-shadow-left: drop-shadow(-1px 2px 2px rgba(0, 0, 0, 0.05));

$break-iPhone5: 325px;
$break-iPhone8: 380px;
$break-iPhone8Plus: 415px;
$break-desktop: 1000px;

$max-page-width: 450px;

/**
* Theme rules
*
* Light is the default value
*
* Theme type Light, light text. (default)
* Theme type Dark, dark text.
*/

.light-theme-text {
    color: $primary-white-color;
}

.light-theme-background {
    background-color: $primary-white-color;
}

.dark-theme-text {
    color: $primary-black-color;
}

.dark-theme-background {
    background-color: $primary-black-color;
}

.alert {
    border: 1px solid transparent;
    filter: $default-box-shadow-left;
    margin: $default-margin 0;
    padding: $default-margin;
}

.alert-info {
    background-color: #d9edf7;
    color: #31708f;
}

.alert-danger {
    background-color: #f2dede;
    color: #a94442;
}

.top-buffer {
    margin-top: $default-margin;
}

.title {
    color: $primary-white-color;
    font-weight: bold;
    max-width: $max-page-width;
}

.row {
    margin: $default-margin * 2 0;
    width: 100%;
    max-width: $max-page-width;
}

.form-base-style {
    // Default styles to add for forms, inputs and buttons
    margin: math.div($default-margin, 2) auto 0;
    max-width: $max-page-width;
    width: 100%;
    padding: math.div($default-padding, 2);
    border: 0;
    box-sizing: border-box; // Needed to prevent textarea from bleeding
}
