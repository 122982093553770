.package-table {
    margin: 0;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
    tr {
        td {
            vertical-align: middle;
            display: table-cell;
        }
        td.expand {
            cursor: pointer;
            font-size: 30px;
            font-weight: bold;
            width: 55px;
        }
        td:last-child {
            text-align: right;
        }
    }
}
