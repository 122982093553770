@import '../../styles/defaults.scss';

.customer-service-number  {
    text-decoration: none;
    color: $primary-blue-color;

    &:hover {
        text-decoration: underline;
    }
}
