@import './defaults.scss';

html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 1em;

    @media screen and (max-width: $break-iPhone5) {
        font-size: 0.8em;
    }
}

#root {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.delivery-background {
    top: 30%;
    position: absolute;
    background-color: $primary-black-color;
    height: 70%;
    width: 100%;

    &::before {
        background: white;
        bottom: 100%;
        content: ' ';
        display: block;
        height: 30%;
        position: absolute;
        right: 0;
        transform-origin: 100% 100%;
        transform: rotate(-10deg);
        width: 150%;
    }
}

.note {
    color: $primary-grey-color;
    font-size: small;
}

.text--center {
    text-align: center;
}

$default-offset: 2;

@for $i from 0 through 5 {
    .pa-#{$i} {
        padding: #{$i * $default-offset}px;
    }
    .px-#{$i} {
        padding-left: #{$i * $default-offset}px;
        padding-right: #{$i * $default-offset}px;
    }
    .py-#{$i} {
        padding-top: #{$i * $default-offset}px;
        padding-bottom: #{$i * $default-offset}px;
    }
    .pt-#{$i} {
        padding-top: #{$i * $default-offset}px;
    }
    .pb-#{$i} {
        padding-bottom: #{$i * $default-offset}px;
    }
    .pl-#{$i} {
        padding-left: #{$i * $default-offset}px;
    }

    .pr-#{$i} {
        padding-right: #{$i * $default-offset}px;
    }

    .ma-#{$i} {
        margin: #{$i * $default-offset}px;
    }
    .mx-#{$i} {
        margin-left: #{$i * $default-offset}px;
        margin-right: #{$i * $default-offset}px;
    }
    .my-#{$i} {
        margin-top: #{$i * $default-offset}px;
        margin-bottom: #{$i * $default-offset}px;
    }
    .mt-#{$i} {
        margin-top: #{$i * $default-offset}px;
    }
    .mb-#{$i} {
        margin-bottom: #{$i * $default-offset}px;
    }
    .ml-#{$i} {
        margin-left: #{$i * $default-offset}px;
    }
    .mr-#{$i} {
        margin-right: #{$i * $default-offset}px;
    }
}
