@import '../../styles/defaults.scss';

.time-to-arrival-wrapper {
    align-items: center;
    background-color: $primary-blue-color;
    border-radius: $right-border-radius;
    color: $primary-white-color;
    filter: $default-box-shadow-right;
    height: auto;
    padding: $default-padding;
    position: relative;

    @media screen and (max-width: $break-iPhone5) {
        width: 150px;
    }

    div:nth-child(even) {
        font-size: 24px;
        font-weight: bold;
        @media screen and (max-width: $break-iPhone5) {
            font-size: 1.5em;
        }
    }

    .refresh {
        border-radius: 50%;
        height: 28px;
        left: 100%;
        margin: -28px 0 0 -28px;
        padding: 10px;
        position: absolute;
        text-align: center;
        top: 100%;
        width: 28px;
        cursor: pointer;

        img {
            margin: -1px 0 0 0;
        }

        &:active,
        &:hover {
            animation: Refresh 1s;
        }

        &:active {
            top: 102%;
        }
    }
}

@keyframes Refresh {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
