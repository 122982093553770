@import '../../styles/defaults.scss';
.toast {
    display: flex;
    justify-content: space-between;
    background-color: inherit;
    .warning,
    .success,
    .danger,
    .default {
        width: 100%;
        color: $primary-white-color;
        margin: 0 auto;
        padding: $default-padding;
        filter: $default-box-shadow-left;
    }

    .warning {
        background-color: $primary-yellow-color;
    }

    .success {
        background-color: $primary-green-color;
    }

    .danger {
        background-color: $primary-red-color;
    }

    .default {
        background-color: $primary-grey-color;
    }
}