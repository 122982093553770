@import '../../styles/defaults.scss';

.directions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: $default-margin 0;

    .direction {
        padding: $default-padding;
        border-radius: $default-border-radius;
        cursor: pointer;
        background-color: $primary-white-color;
    }
}
