@use 'sass:math';
@import '../../../styles/defaults.scss';

.sig-canvas {
    width: 100%;
    border-radius: $default-border-radius;
    margin: math.div($default-margin, 2) auto 0;
    border: 0;
    background-color: $primary-white-color;
}

.blob-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        @extend .form-base-style;
        background-color: $primary-red-color;
        border-radius: $default-border-radius;
        color: $primary-white-color;
        width: 55px;
        margin: 0;
        padding: math.div($default-padding, 8);
    }
}

.printed-name {
    @extend .form-base-style;
    border-radius: $default-border-radius;
}
