@use 'sass:math';
@import '../../styles/defaults.scss';

.position-in-route-wrapper {
    align-items: center;
    background-color: $primary-white-color;
    border-radius: math.div($default-border-radius, 2);
    display: flex;
    filter: $default-box-shadow-left;
    margin: 10px 10px 10px 20px;
    padding: 20px 40px 20px 20px;
    position: relative;
    @media screen and (max-width: $break-desktop) {
        width: 200px;
    }
    @media screen and (max-width: $break-iPhone8Plus) {
        width: 170px;
    }
    @media screen and (max-width: $break-iPhone8) {
        width: 140px;
    }
    @media screen and (max-width: $break-iPhone5) {
        width: 120px;
    }

    &.no-shadow {
        filter: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 10px;
        border: solid transparent;
        content: ' ';
        height: 0;
        margin-top: -10px;
        pointer-events: none;
        position: absolute;
        right: 100%;
        top: 50%;
        width: 0;
    }
    span {
        strong {
            color: #06d4fe;
        }
        .text-is-expanded {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;
            img {
                max-width: 20px;
                height: auto;
            }
            cursor: pointer;
        }
        .blink {
            animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
            cursor: pointer;
        }
    }
}

@keyframes blinker {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
