@import '../../styles/defaults.scss';

.pin {
    background: #E13205;
    border-radius: 50% 50% 50% 0;
    height: 30px;
    left: 50%;
    margin: -20px 0 0 -20px;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    width: 30px;
    &:after {
        background: $primary-white-color;
        border-radius: 50%;
        content: '';
        height: 14px;
        margin: 8px 0 0 8px;
        position: absolute;
        width: 14px;
    }
}

.bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    @keyframes bounce {
        0% {
            opacity: 0;
            transform: translateY(-2000px) rotate(-45deg);
        }

        60% {
            opacity: 1;
            transform: translateY(30px) rotate(-45deg);
        }

        80% {
            transform: translateY(-10px) rotate(-45deg);
        }

        100% {
            transform: translateY(0) rotate(-45deg);
        }
    }
}

.pulse {
    background: $primary-black-color;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
    &:after {
        content: '';
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -13px 0 0 -13px;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        box-shadow: 0 0 1px 2px $primary-black-color;
        animation-delay: 1.1s;
    }

    &.black {
        &:after {
            animation: pulsate-large 1s ease-out;
            animation-iteration-count: infinite;
            box-shadow: 0 0 1px 2px black;
        }
    }

    @keyframes pulsate {
        0% {
            transform: scale(0.1, 0.1);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1.2, 1.2);
            opacity: 0;
        }
    }

    @keyframes pulsate-large {
        0% {
            transform: scale(0.1, 0.1);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1.5, 1.5);
            opacity: 0;
        }
    }
}

.truck-icon {
    margin-left: -25px;
    margin-top: -10px;
    position: relative;
    z-index: 1;
}
