@import '../../styles/defaults.scss';

.cancel-delivery-btn {
    @extend .form-base-style;
    border-radius: $default-border-radius;
    background-color: $primary-red-color;
    color: $primary-white-color;
    text-decoration: none;
    cursor: pointer;
    &:active {
        filter: none;
        transition: transform 0.1s;
        transform: translateY(1px);
    }
}

.cancel-delivery-warning {
    @extend .form-base-style;
    color: $primary-red-color;
    font-size: 1.5em;
}

.loader {
    color: $primary-white-color;
    font-size: 70px;
    font-weight: bolder;
    span {
        animation: 1s blinker infinite;
    }
    span:nth-child(2) {
        animation-delay: 250ms;
    }
    span:nth-child(3) {
        animation-delay: 500ms;
    }

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
}
