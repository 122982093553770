@use 'sass:math';
@import '../../../styles/defaults.scss';

.estimated-delivery-time {
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    transition: opacity 1500ms ease-in;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        margin: math.div($default-margin, 2) 0;
        border-radius: $default-border-radius;
        background-color: $primary-white-color;
        color: $primary-black-color;
        padding: math.div($default-padding, 2);
        display: flex;
        flex-direction: column;

        strong {
            font-weight: bold;
            font-size: 0.7em;
        }

        small {
            font-size: 0.5em;
            font-weight: lighter;
            opacity: 0.6;
        }
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
