@import '../../../styles/defaults.scss';

.identification-wrapper {
    .identification-alerts {
        div:nth-child(2) {
            margin-top: $default-margin;
        }
    }
    .identification-buttons {
        margin-top: $default-margin;
    }
    .verification-code {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        margin: ($default-margin * 2) auto;
        letter-spacing: 25px;
        font-size: 65px;
    }
}