@use 'sass:math';
@import '../../../styles/defaults.scss';

.statuses {
    background-color: inherit;
    color: $primary-white-color;
    margin: math.div($default-margin, 2) 0;

    .status-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: $default-margin 0;

        .status-image {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            margin-right: math.div($default-margin, 2);
            max-width: 30px;
            padding: math.div($default-padding, 2);

            img {
                width: 100%;
                height: auto;
            }
        }

        .status {
            width: 100%;
            .status-title {
                margin-bottom: math.div($default-margin, 2);
                font-weight: bold;
            }
        }
    }

    .show-all-statuses {
        align-items: center;
        display: flex;
        justify-content: center;
        span {
            color: $primary-blue-color;
        }
    }
}

.status-divider:last-child {
    display: none;
}
